import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAiTools, getAiTags, addToFavorites, removeFromFavorites } from '../../../services/aiTools/aiToolsService';
import { StarIcon } from '@heroicons/react/24/outline'; // Import Heroicons
import { useTranslation } from 'react-i18next';

export default function Tools() {
  const [allTools, setAllTools] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [tags, setTags] = useState([{ uuid: 'all', name: 'All' }]);
  const [activeTag, setActiveTag] = useState('all');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [t] = useTranslation();

  useEffect(() => {
    // Fetch AI tools on component mount
    const fetchAiTools = async () => {
      try {
        const [tools, fetchedTags] = await Promise.all([getAiTools(), getAiTags()]);

        // Separate favorite tools
        const favoriteTools = tools.filter((tool) => tool.is_in_favorites);

        setAllTools(tools);
        setFavorites(favoriteTools);
        setTags([{ uuid: 'all', name: 'All' }, ...fetchedTags]); // Add 'All' as the default filter
      } catch (err) {
        console.error('Error fetching AI tools:', err);
        setError('Failed to load AI tools. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchAiTools();
  }, []);

  const handleToolClick = (tool) => {
    navigate(`/aitools/${tool.uuid}`);
  };

  const toggleFavorite = async (tool) => {
    try {
      if (tool.is_in_favorites) {
        // Remove from favorites
        await removeFromFavorites(tool.uuid);
      } else {
        // Add to favorites
        await addToFavorites(tool.uuid);
      }

      // Update the tool's favorite status in the main tools array
      const updatedTools = allTools.map((t) =>
        t.uuid === tool.uuid ? { ...t, is_in_favorites: !tool.is_in_favorites } : t
      );

      // Recompute favorites based on the updated tools array
      const updatedFavorites = updatedTools.filter((t) => t.is_in_favorites);

      setAllTools(updatedTools);
      setFavorites(updatedFavorites);
    } catch (error) {
      console.error('Error updating favorites:', error);
      alert('Failed to update favorites. Please try again.');
    }
  };

  const filterToolsByTag = () => {
    if (activeTag === 'all') return allTools;
    return allTools.filter((tool) => tool.tags?.some((tag) => tag.uuid === activeTag));
  };

  return (
    <div className="min-h-screen p-6 bg-gray-50 flex flex-col">
      <h1 className="text-2xl font-bold mb-2 text-center">AI Tools - Demo</h1>
<p className="text-sm text-gray-600 text-center mb-6">
  Explore a curated list of powerful AI tools to enhance your productivity and creativity. 
  <span className="block mt-1">{t('aitools.toolslgs')}</span>
</p>


      {/* Loading State */}
      {loading && (
        <div className="flex justify-center items-center">
          <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
          <p className="ml-2 text-lg">Loading AI tools...</p>
        </div>
      )}

      {/* Error State */}
      {error && (
        <div className="p-4 bg-red-100 text-red-700 border border-red-500 rounded-lg text-center">
          <p>{error}</p>
        </div>
      )}

      {/* Favorites Section */}
{!loading && !error && (
  <div className="mb-8">
    <h2 className="text-xl font-semibold mb-4">Favorites</h2>
    {favorites.length > 0 ? (
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        {favorites.map((tool) => (
          <div
            key={tool.uuid}
            className="relative flex items-center border border-gray-300 rounded-2xl p-4 bg-white shadow-md hover:shadow-lg transition-transform cursor-pointer"
            onClick={() => handleToolClick(tool)}
          >
            {/* Tool Logo */}
            {tool.logo && (
              <img
                src={tool.logo}
                alt={`${tool.name} logo`}
                className="w-16 h-16 mr-4 rounded-md"
              />
            )}

            {/* Tool Content */}
            <div className="flex-1">
              <h2 className="font-bold text-lg mb-1">{tool.name}</h2>
              <p className="text-gray-600 text-sm">
                {tool.description || 'No description available.'}
              </p>
            </div>

            {/* Star Icon for Favorite */}
            <div
              className={`absolute top-4 right-4 cursor-pointer ${
                tool.is_in_favorites
                  ? 'text-yellow-500'
                  : 'text-gray-400 hover:text-yellow-500 transition-colors'
              }`}
              onClick={(e) => {
                e.stopPropagation(); // Prevent triggering tool click
                toggleFavorite(tool);
              }}
            >
              <StarIcon
                className={`h-6 w-6 ${
                  tool.is_in_favorites ? 'fill-yellow-500' : ''
                }`}
              />
            </div>
          </div>
        ))}
      </div>
    ) : (
      <div className="p-4 bg-gray-100 text-gray-600 border border-gray-300 rounded-lg text-center">
        <p>Your favorite tools appear here! Click the star on any tool to add it to your favorites.</p>
      </div>
    )}
  </div>
)}

{/* All Tools Section */}
{!loading && !error && (
  <div>
    <h2 className="text-xl font-semibold mb-4">All Tools</h2>
    <div className="flex overflow-x-auto gap-2 mb-4">
      {tags.map((tag) => (
              <button
                key={tag.uuid}
                onClick={() => setActiveTag(tag.uuid)}
                className={`${
                  activeTag === tag.uuid
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-100 text-gray-600'
                } border-2 rounded-full px-4 py-2 whitespace-nowrap hover:bg-gray-200 transition-colors`}
              >
                {tag.name}
              </button>
            ))}
    </div>

    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
      {filterToolsByTag().map((tool) => (
        <div
          key={tool.uuid}
          className="relative flex items-center border border-gray-300 rounded-2xl p-4 bg-white shadow-md hover:shadow-lg transition-transform cursor-pointer"
          onClick={() => handleToolClick(tool)}
        >
          {/* Tool Logo */}
          {tool.logo && (
            <img
              src={tool.logo}
              alt={`${tool.name} logo`}
              className="w-16 h-16 mr-4 rounded-md"
            />
          )}

          {/* Tool Content */}
          <div className="flex-1">
            <h2 className="font-bold text-lg mb-1">{tool.name}</h2>
            <p className="text-gray-600 text-sm">
              {tool.description || 'No description available.'}
            </p>
          </div>

          {/* Star Icon for Favorite */}
          <div
            className={`absolute top-4 right-4 cursor-pointer ${
              tool.is_in_favorites
                ? 'text-yellow-500'
                : 'text-gray-400 hover:text-yellow-500 transition-colors'
            }`}
            onClick={(e) => {
              e.stopPropagation(); // Prevent triggering tool click
              toggleFavorite(tool);
            }}
          >
            <StarIcon
              className={`h-6 w-6 ${
                tool.is_in_favorites ? 'fill-yellow-500' : ''
              }`}
            />
          </div>
        </div>
      ))}
    </div>
  </div>
)}


    </div>
  );
}