import bilAPI from '../api';


export const getLessons = ({ uuid, section, order }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/courses/lessons/${uuid ? uuid + '/' : ''}`, {
          params: { section, order },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};


export const getLessonContent = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/courses/lessons/${uuid}/content/`) 
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};


export const postLesson = ({ name, section, duration, }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/courses/lessons/`, {
          name,
          section,
          order: 0, 
          duration,
           
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};


export const patchLesson = ({ uuid, name, duration,  }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/courses/lessons/${uuid}/`, {
          name,
          duration,
          
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};


export const deleteLesson = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/courses/lessons/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};


export const postIncreaseLessonOrder = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/courses/lessons/${uuid}/increase_order/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};


export const postDecreaseLessonOrder = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/courses/lessons/${uuid}/decrease_order/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};
