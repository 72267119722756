import bilAPI from '../api';
import { duration } from 'moment/moment';


export const getCourses = ({ uuid }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/courses/courses/${uuid ? uuid + '/' : ''}`, {
          params: {},
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};


export const getStartedCourses = (account) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/courses/courses/get_started/`, {
          params: { account },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};


export const startCourse = ({ uuid }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/courses/courses/${uuid}/start/`)
        .then((res) => {
          resolve(res?.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};


export const postCourse = ({ name, description, key_facts, duration, authors, }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/courses/courses/`, {
          name,
          description,
          key_facts,
          duration,
          authors,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};


export const patchCourse = ({ uuid, name, description, key_facts, duration, authors }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/courses/courses/${uuid}/`, {
          name,
          description,
          key_facts,
          duration, 
          authors,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};


export const deleteCourse = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/courses/courses/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};