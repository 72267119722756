import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { getLessons } from '../../../../services/courses/lessonService';
import Content from '../../Lesson/Student/Contents/Content';
import CircleProgressBar from '../../../../components/charts/CircleProgressBar';
import VideoContent from '../../Lesson/Student/Contents/VideoContent';
/*import QuestionContent from '../../Lesson/Student/Contents/QuestionContent'; */
/*import TextContent from '../../Lesson/Student/Contents/TextContent';*/
import Clock from '../../../../components/Icons/Clock';
import avatarPlaceholder from '../../../../assets/images/avatar_placeholder.jpg';
import CheckMark from '../../../../components/Icons/CheckMark';

const Student = ({ data: sections, course, data, refreshData }) => {
  const { t } = useTranslation();
  const [expandedSections, setExpandedSections] = useState(new Set());
  const [lessons, setLessons] = useState({});
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [lessonContent, setLessonContent] = useState([]);
  const [completedLessons, setCompletedLessons] = useState(new Set());
  console.log(course);

  const getLessonsData = useCallback((sectionUuid) => {
    getLessons({ section: sectionUuid })
      .then((res) => {
        setLessons((prev) => ({
          ...prev,
          [sectionUuid]: res.results || [],
        }));
      })
      .catch(() => {
        setLessons((prev) => ({
          ...prev,
          [sectionUuid]: [],
        }));
      });
  }, []);

  const getLessonContent = useCallback((lessonUuid) => {
    getLessons({ uuid: lessonUuid })
      .then((res) => {
        setSelectedLesson(res);
        setLessonContent(res?.content || []);
      })
      .catch(() => {
        setSelectedLesson(null);
        setLessonContent([]);
      });
  }, []);

  const toggleSection = (sectionUuid) => {
    setExpandedSections((prev) => {
      const updated = new Set(prev);
      if (updated.has(sectionUuid)) {
        updated.delete(sectionUuid);
      } else {
        updated.add(sectionUuid);
      }
      return updated;
    });
    getLessonsData(sectionUuid);
  };

  const handleLessonClick = (lesson) => {
    getLessonContent(lesson.uuid);
  };

  {/*const handleCheckboxClick = (lessonId) => {
    setCompletedLessons((prev) => {
      const updated = new Set(prev);
      if (updated.has(lessonId)) {
        updated.delete(lessonId);
      } else {
        updated.add(lessonId);
      }
      return updated;
    });
  };*/}

  const handleVideoEnded = (lessonId) => {
    setCompletedLessons((prev) => new Set(prev).add(lessonId));
  };

  const formatDuration = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    if (hours > 0) {
      return remainingMinutes > 0
        ? `${hours} ч ${remainingMinutes} мин`
        : `${hours} ч`;
    }
    return `${remainingMinutes} мин`;
  };

  return (
    <div className="flex flex-col min-h-screen">
      <div className=" text-white rounded-xl py-4 px-8 mt-4" style={{ backgroundColor: '#2F80ED' }}>
        <h1 className="text-2xl font-bold text-center">{course.name}</h1>
      </div>

      <div className="flex flex-col lg:flex-row flex-1">
        {/* Основной контент */}
        {selectedLesson ? (
          <div className="w-full h-full justify-center">
            {/*{selectedLesson?.video_content && (
              <VideoContent
                videoUrl={selectedLesson.video_content.url}
                onVideoEnded={() => handleVideoEnded(selectedLesson.uuid)}
              />
            )}*/}
            <div className="mt-6">
              {Array.isArray(lessonContent) && lessonContent.length > 0 ? (
                lessonContent.map((content, index) => (
                  <Content key={index} data={content} />
                ))
              ) : (
                <p className="text-gray-500 italic">{t('No content available for this lesson.')}</p>
              )}
            </div>
          </div>
        ) : (
          <div className="text-center w-full">
            <div className="flex flex-row items-center py-3">
              <div className="flex flex-col">
                <p className="text-lg text-gray-700">
                  <span className="font-bold">{t('Author')}:</span>
                  {course?.authors?.[0]?.author?.first_name || course?.authors?.[0]?.author?.last_name ? (
                    <span className="ml-1">{`${course?.authors?.[0]?.author?.first_name || ''} ${course?.authors?.[0]?.author?.last_name || ''}`.trim()}</span>
                  ) : (
                    <span className="ml-1">{t('No Author Information')}</span>
                  )}
                </p>
              </div>
              <div className="shrink-0 ml-4">
                <img
                  src={
                    course?.authors?.[0]?.author?.profile_picture || avatarPlaceholder
                  }
                  alt="avatar"
                  className="md:w-12 md:h-12 lg:w-12 lg:h-12 w-10 h-10 rounded-full object-cover"
                />
              </div>
            </div>

            <ul className="text-gray-700 flex flex-col justify-center space-y-2 text-xl mt-6">
              <p className='text-left text-gray-700 md:font-bold'>{t('You Will Learn How To')}</p>
              {course?.key_facts?.length > 0 ? (
                course.key_facts.map((fact, index) => (
                  <li key={index} className="flex items-center">
                    <CheckMark className="text-green-500 w-6 h-6 flex-shrink-0 mr-6" />
                    {fact}
                    <span className="leading-tight"></span>
                  </li>
                ))
              ) : (
                <p className="text-gray-500 italic">{t('No key facts available')}</p>
              )}
            </ul>
            <p className="py-6 text-left text-xl text-gray-700 md: font-bold">
              {t('About Course')}
              <p className='text-gray-700 text-lg md: font-normal py-2'>
                {course?.description || t('No course description available')}
              </p>
            </p>
          </div>
        )}

        {/* Сайдбар */}
        <div className="lg:w-1/3 bg-white shadow-md border border-gray-200 h-full sticky top-0 mt-10 rounded-3xl overflow-hidden">
          <div>
            <div className="flex justify-between items-center p-4 border-b border-gray-200">
              <h2 className="text-lg font-bold">{t('Материалы курса')}</h2>
              <div className="flex items-center text-green-600 text-sm">
                <Clock className="w-5 h-5 mr-1" />
                {formatDuration(course?.duration)}
              </div>
            </div>

            {sections?.map((section) => (
              <div key={section.uuid}>
                <div
                  className={`flex items-center justify-between px-4 py-3 cursor-pointer border-b border-gray-200 ${expandedSections.has(section.uuid) ? 'bg-blue-50 font-bold' : 'hover:bg-gray-100'
                    }`}
                  onClick={() => toggleSection(section.uuid)}
                >
                  <div className="flex flex-col gap-1">
                    <div className="flex items-center gap-2">
                      <CircleProgressBar progress={(section.section_status * 100).toFixed()} />
                      <span>{section.name}</span>
                    </div>
                    <div className="text-gray-500 text-xs flex items-center mt-1">
                      <Clock className="w-4 h-4 mr-1" />
                      {formatDuration(section.duration)}
                    </div>
                  </div>
                  <ChevronDownIcon
                    className={`h-5 transform transition-transform duration-300 ${expandedSections.has(section.uuid) ? 'rotate-180' : ''
                      }`}
                  />
                </div>

                {expandedSections.has(section.uuid) && (
                  <div>
                    {(lessons[section.uuid] || []).map((lesson) => (
                      <div
                        key={lesson.uuid}
                        className={`border-b border-gray-200 cursor-pointer ${selectedLesson?.uuid === lesson.uuid ? 'bg-blue-100 font-bold' : 'hover:bg-gray-100'
                          }`}
                        onClick={() => handleLessonClick(lesson)}
                      >
                        <div className="flex items-center gap-2 px-4 py-2">
                          {/*<div className="flex-shrink-0 h-5 w-5">
                            <input
                              type="checkbox"
                              checked={completedLessons.has(lesson.uuid)}
                              onChange={(e) => {
                                e.stopPropagation(); // Предотвращаем срабатывание onClick на родительском элементе
                                handleCheckboxClick(lesson.uuid);
                              }}
                              className="h-5 w-5 rounded border-gray-300 text-blue-600 focus:ring-blue-500 cursor-pointer"
                            />
                          </div>*/}
                          <span className="flex-grow">{lesson.name}</span>
                        </div>
                        <div className="text-gray-500 text-xs flex items-center ml-3 mt-1">
                          <Clock className="w-4 h-4 ml-1 -translate-y-1 mt-2" />
                          <span className="ml-2">{formatDuration(lesson.duration)}</span>
                        </div>





                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Student;
